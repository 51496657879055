import React from 'react'
import Container from '@material-ui/core/Container'
import Button from '@material-ui/core/Button';

import Layout from '../components/Layout'
import BlogRoll from '../components/BlogRoll'
import PickupTags from '../components/PickupTags'

const text = "日々の情報からどれだけアイデアを抽出できているのでしょうか。\n組み合わせる点をたくさん用意することで、アイデアへの地図を作る。それがこのサイトのゴールです。"

export const IndexPageTemplate = () => (
  <>
    <section className="Section">
      <Container>
        <h2 className="PageTitle-catch">
          アイデアとは既存の要素の新しい組み合わせ以外の何ものでもない
          <span>ジェームス・ウェブ・ヤング</span>
        </h2>
        <h3 className="TextCenter" style={{whiteSpace: 'pre-line'}}>{text}</h3>
      </Container>
    </section>
    <section className="Section">
      <Container>
        <div className="PageTitle-section">
          <h3 className="TextCenter">
            Recent ideas<span>最近のアイデア</span>
          </h3>
        </div>
        <BlogRoll />
        <div className="MuiButton-wrap">
          <Button className="MuiButton-normal" href="/ideas">
            More
          </Button>
        </div>
      </Container>
    </section>
    <PickupTags />
  </>
)

const IndexPage = () => {
  return (
    <Layout>
      <IndexPageTemplate/>
    </Layout>
  )
}

export default IndexPage
